import React, { Component } from "react"
import { Link } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import SEO from "../components/seo"

//mui
import Button from "@material-ui/core/Button"

const styles = theme => ({})

export class template extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="template"
          keywords={[`adc`, `American Dream Construction`]}
        />
        <div className="container-body container-centered container-middle">
          <h1> Success! </h1>
          <Button component={Link} to="/contact">
            {" "}
            Return{" "}
          </Button>
        </div>
      </Layout>
    )
  }
}

export default withStyles(styles, { withTheme: true })(template)
